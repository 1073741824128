@font-face {
  font-family: "mulish";
  /* src: url('../assets/fonts/Mulish-Light.ttf'); */
  src: url('../assets/fonts/Mulish-Light.ttf');

}
@font-face {
  font-family: "mulish-bold";
  src: url('../assets/fonts/Mulish-Bold.ttf');
}
@font-face {
  font-family: "mulish-extra-bold";
  src: url('../assets/fonts/Mulish-ExtraBold.ttf');
}


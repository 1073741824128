@import url("./assets/styles.css");
@import url("https://fonts.googleapis.com/css2?family=Familjen+Grotesk:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primaryColor: rgb(244, 242, 242);
  --secondaryColor: rgb(255, 255, 255);
  --heading-1: #505050;
  --themeOrangeLight: #fdf1ec;
  --themeOrange: #ec5f2a;
  --emailTextColor: #3362dd;
  --InputTextGray: #928b8b;
  --fontsFamily: "Familjen Grotesk", sans-serif;
  --legendColor: #544f4f;
  --percentageColor: #bff5e7;
  --draftCounter: #f49c7c;
  --draftBgcolor: #f5f4f4;
  --percentageTextColor: #201e1e;
  --intermediateBackground: #faad15;
  --negativePercentageBox: #ffcece;
  --paginationTextColor: #797979;
  --trainingText: #5f5959;
  --checkboxBorder: #cecaca;
  --comparisionColor: #ef7143;
  --dashBoardEmptyText: #7e7777;
  --InputMessage: #696363;
  --BtnHoverColor: #f07f56;
  --errorsColor: #da2525;
  --loaderBackground: rgba(36, 36, 36, 0.772);
  --orangeDisabled: #f5aa8e;
  --lightGray: #f5f4f4;
  --borderInput: #eceaea;
  --topPerformer: #06d6a0;
  --topMentors: #71250a;
  --searchColor: #9c9696;
  --assignFadeBtn: #d8d5d5;
  --emailTextForAssign: #888181;
  --openAIChip: #fef1d7;
  --activityText: #4a4545;
  --otpBg: #f5f4f4;
  --fontMulish: "Mulish", sans-serif;
  --fontFamiljen: "Familjen Grotesk", sans-serif;
  --mulish: "mulish-extra-bold";
  --mulishBold: "mulish-bold";
  --font100: 100;
  --font200: 200;
  --font300: 300;
  --font400: 400;
  --font500: 500;
  --font600: 600;
  --font700: 700;
  --font800: 800;
  --font900: 900;
  --optionBtnBg: #fce3d9;
  --lightGrayBorder: #dcdada;
  --lightBlue: #e3f2fd;
  --btnGray: #eceaea;
  --btnGrayHover: #dcdcdc;
  --alertBoxOrange: rgba(210, 121, 121, 0.3);
  --inerTextColor: #3f3b3b;
  --black: #100f0f;
  --orangeScore: #fad4c7;
}

.linkactive {
  background-color: #252424 !important;
  color: #ec5f2a !important;
}
.linkactive svg {
  color: #ec5f2a !important;
}
.styledTableRow:hover {
  background-color: #f0eaea !important;
}
#lms span {
  text-transform: uppercase !important;
}
.css-zxdg2z {
  width: 80% !important;
  height: 30rem !important;
}
.errorsPlaceholder {
  color: var(--errorsColor);
  font-family: var(--fontsFamily);
  border: 1.5px solid var(--errorsColor) !important;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #f5f4f4;
  width: 100%;
  font-size: 1rem;
}
.errorsPlaceholderclient {
  color: var(--errorsColor);
  font-family: var(--fontsFamily);
}
.errorsPlaceholder::placeholder {
  color: var(--errorsColor) !important;
}
.active {
  outline: 1.5px solid var(--BtnHoverColor);
  background-color: var(--themeOrangeLight);
}

.activeProgram {
  color: #ffffff !important;
  background-color: var(--themeOrange) !important;
  border-radius: 4px !important;
}

.rdt_TableCol input[type="checkbox"] {
  width: 18px !important;
  height: 18px !important;
}
.rdt_TableRow input[type="checkbox"] {
  width: 18px !important;
  height: 18px !important;
}
.rdt_TableCell:nth-child(6) {
  justify-content: end;
}
.rdt_TableCol:nth-child(6) {
  justify-content: end;
}
.rdt_TableCell {
  font-family: var(--fontFamiljen);
  font-weight: var(--font400);
  font-size: 14px;
}
.rdt_TableCol {
  font-family: var(--fontFamiljen);
  font-weight: var(--font500);
  font-size: 14px;
  color: var(--InputTextGray);
}

.cNxqtp:nth-of-type(n) {
  background-color: var(--themeOrangeLight) !important;
}
.errors {
  color: var(--errorsColor) !important;
  font-size: 12px;
  font-family: var(--fontsFamily);
  display: grid;
  justify-content: end;
}
.otpWidth {
  width: 50px !important;
  height: 50px;
  border: 1.5px solid var(--borderInput);
  border-radius: 8px;
  margin-bottom: 1rem;
  background-color: var(--otpBg);
}
.otpWidth:focus-visible {
  outline: 1px solid var(--themeOrange);
  background-color: var(--themeOrangeLight);
}
.otpWidth:focus {
  border: 1.5px solid var(--themeOrange) !important;
  border-radius: 8px;
}
@media (max-width: 768px) {
  .otpWidth {
    width: 40px !important;
    height: 40px;
  }
}
.otpContainer {
  justify-content: space-between;
}
.errorHandleMessage {
  color: var(--errorsColor);
  font-size: 0.75rem;
}
.styleSelect {
  padding: 1rem;
  outline: none;
  border: none;
  border: 1px solid var(--borderInput);
  display: block;
  background-color: #f5f4f4;
  font-family: var(--fontsFamily);
  font-size: 16px;
  border-radius: 8px;
  width: 100%;
  color: var(--InputTextGray);
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.selectLabel {
  display: block;
  width: 100%;
  margin: 0;
  font-family: var(--fontsFamily);
  color: var(--legendColor);
  margin-bottom: 0.2rem;
  font-size: 14px;
}
.BtnHoverDisable {
  background-color: var(--btnGray) !important;
}
.BtnHoverDisable:hover {
  background-color: var(--btnGrayHover) !important;
  transition: ease all 0.5s;
}
.attachmentFileBTN {
  text-transform: capitalize;
  font-weight: var(--font500);
  font-family: var(--fontFamiljen);
  font-size: 12px;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  transition: ease all 0.5s;
  color: var(--themeOrange);
  border-radius: 0.25rem;
}
.attachmentFileBTN:hover {
  background-color: var(--themeOrangeLight);
}
.rdrDefinedRangesWrapper {
  display: none !important;
}
.removeInputArrowIconsinput::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--borderInput) !important;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.06) !important;
}
.MuiDialog-paper {
  padding: 1.5rem 1rem !important;
  border-radius: 1rem !important;
  max-width: 23rem !important;
}
.removerHoverfromBtn:hover {
  background-color: var(--borderInput) !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 7px;
}
::-webkit-scrollbar-track {
  background: #f2f2f2;
}
::-webkit-scrollbar-thumb {
  background: var(--themeOrange);
}
.activeOtp {
  outline: 1.5px solid var(--BtnHoverColor);
  background-color: var(--themeOrangeLight);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
